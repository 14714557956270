.ui.dimmer {
  background-color: rgba(57, 57, 57, 0.85);
}

.ui.segment.pushable {
  border: 0;
}

.ui.page.modals.dimmer.transition.visible.active {
  z-index: 9999;
}
