$breakpoint-mobile: 768px;
$breakpoint-tablet: 1024px;

.sideNavExpanded {
  * {
    color: white !important;   
  }

  nav {
    background-color: #354052;
    z-index: 999;
    
    @media only screen and (max-width: $breakpoint-mobile) {
      overflow-y: auto;
      overflow-x: hidden;
    }

    button {
      width: 90px;

      @media only screen and (max-width: $breakpoint-mobile) {
        height: 50px;
      }
    }

    .navText {
      margin-left: 25px;
    }

    .navToggle {
      width: 90px;
    }
  }
}

.sideNavCollapsed {
  * {
    color: white !important;   
  }

  nav { 
    background-color: #354052;
    z-index: 999;
    
    @media only screen and (max-width: $breakpoint-mobile) {
      background-color: #fff;
      height: 50px;
    }

    button {
      width: 90px;

      @media only screen and (max-width: $breakpoint-mobile) {
        height: 50px;
        border-bottom: 1px solid #d4d4d5 !important;

        span {
          background-color: black !important;
        }
      }
    }
    
    .navText {
      margin-left: 25px;
    }

    .navToggle {
      width: 90px;
    }

    .sideNavigation {
      @media only screen and (max-width: $breakpoint-mobile) {
        display: none;
      }
    }
  }
}

.expanded {
  padding-left: 240px;
  transition: 0.2s;

  @media only screen and (max-width: $breakpoint-tablet) {
    padding-left: 90px;
    transition: 0.2s;
    
    img[id="binaryLogo"] {
      margin-left: 15px !important;
      transition: 0.2s;
    }
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    padding-left: 0px;
    transition: 0.2s;
    
    img[id="binaryLogo"] {
      margin-left: 105px !important;
      transition: 0.2s;
    }
  }
}

.collapsed {
  padding-left: 90px;
  transition: 0.2s;

  @media only screen and (max-width: $breakpoint-tablet) {
    padding-left: 90px;
    transition: 0.2s;

    img[id="binaryLogo"] {
      margin-left: 15px !important;
      transition: 0.2s;
    }
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    padding-left: 0px;
    transition: 0.2s;

    img[id="binaryLogo"] {
      margin-left: 105px !important;
      transition: 0.2s;
    }
  }
}

.iconWrapper {
  height: 50px;
  cursor: pointer;
  width: 90px;

  .iconStyle {
    font-size: 1.75em;
  }
}
