@import './single-date-picker.scss';
@import './form-data.scss';
@import './button.scss';
@import './other-styles.scss';
@import './popup';

.fullHeightLoader {
  height: 100vh;
}

.fullHeightData{
  height: calc(100vh - 50px);
}

.fullWidthData {
  width: 100%;
}