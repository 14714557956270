$main-color: #3498db;
$secondary-color: #0062a7;

$color-text: #44413c;
$color-text-title: #34343C;
$color-text-light: #6c6c74;
$color-text-lightest: #8b90a0;

$color-border-light: #ccc;

$page-background: #f6fafd;

$input-text-color: #555555;

$date-label-color: #8b90a0;

$icon-btn-color: #8b90a0;
$icon-btn-hover-color: #232735;
