
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-family: "Open Sans", Arial, sans-serif !important;
}

p, div, h1, h2 {
  font-family: "Open Sans", Arial, sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sr-only {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;

  overflow: hidden;

  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

#bodyTable {
  background: transparent !important;
}

#templatePreheader{
  background: transparent !important;
}

#templateFooter {
  background: transparent !important;
}
