
.ui.form {
  .field.field {
    input:-webkit-autofill {
      border-color: #C3C4CB !important;
      box-shadow: none !important;
      color: $input-text-color !important;
      &:focus {
        border-color: #C3C4CB !important;
        box-shadow: none !important;
        color: $input-text-color !important;
      }
    }
  }
}
.field {
  input {
    height: 48px;
    border-color: #C3C4CB !important;
  }
}
.ui.input, .react-datepicker__input-container {
  height: 48px;
  input {
    height: 100% !important;
    color: $input-text-color !important;
    border-color: #C3C4CB !important;
    :focus {
      border-color: #C3C4CB !important;
    }
  }
}
.ui.selection.dropdown {
  height: 48px;
  padding-top: 16px;
  &.multiple {
    padding-top: 9px;
  }
  .dropdown.icon {
    top: 15px !important;
  }
}
.styles_select__x6460, .css-2b097c-container {
  min-height: 48px;
  .css-yk16xz-control, .css-1pahdxg-control {
    height: 100%;
  }
}
