.ui.button  {
  margin-right: 0;
  font-size: 14px;
  font-weight: normal;
  padding-top: 18px;
  padding-bottom: 18px;
}
.ui.button.primary {
  background-color: $main-color;
}
