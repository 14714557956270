@import 'src/styles/variables';


.singleDatepickerGlobal {
  span {
    display: block;
    font-size: 13px;
    font-weight: bold;
    padding-bottom: 2px;
  }
  .ui.popup.transition.visible {
    z-index: 10000;
  }
}

